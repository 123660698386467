import React from 'react'
import Layout from '../components/layout'

function TermsOfService () {
  return (
    <Layout>
      <div className="w-full max-w-[800px] mx-auto mt-[144px] lg:mt-[240px] pb-32">
        <div>
          <div className="text-center">
            <div className="">LegalEye</div>
            <h1 className="text-3xl mt-6 md:text-6xl  md:leading-tight font-GTWalsheimPro font-medium">
              Terms of Service
            </h1>
          </div>
          <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
            Introduction
          </h3>
          <p className="mt-4">
            These terms and conditions apply between <b>You</b>, the User of this Website (including any sub-domains,
            unless expressly excluded by their own terms and conditions), and <b>LawAdvisor</b>, the owner and operator
            of this Website, together known as <b>Parties</b>. Please read these Terms of Service (<b>&quot;Terms&quot;</b>) carefully, as
            they affect your legal rights. Your agreement to comply with and be bound by these Terms is deemed to
            occur upon your first use of the Website. If you do not agree to be bound by these Terms, you should
            stop using the Website immediately.
            <br />
            <br />
            In these Terms, <b>User</b> or <b>Users</b> means any third party that accesses the Website and is not either (i)
            employed by LawAdvisor and acting in the course of their employment or (ii) engaged as a consultant or
            otherwise providing services to LawAdvisor and accessing the Website in connection with the provision
            of such services.
            <br />
            <br />
            You must be at least 18 years of age to use this Website. By using the Website and agreeing to these
            Terms, you represent and warrant that you are at least 18 years of age.
          </p>
          <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
            Acceptable Use and Intellectual Property
          </h3>
          <ol className="list-decimal list-inside">
            <li className="mt-4">
              All Content included on the Website, unless uploaded by Users, is the property of LawAdvisor,
              our affiliates or other relevant third parties. In these terms and conditions, <b>Content</b> means any
              text, graphics, images, audio, video, software, data compilations, page layout, underlying code
              and software and any other form of information capable of being stored in a computer that
              appears on or forms part of this Website, including any such content uploaded by Users. By
              continuing to use the Website, you acknowledge that such Content is protected by copyright,
              trademarks, database rights and other intellectual property rights. Nothing on this site shall be
              construed as granting, by implication, estoppel, or otherwise, any license or right to use any
              trademark, logo or service mark displayed on the site without the owner&apos;s prior written
              permission.
            </li>
            <li className="mt-4">
              You may, for your own personal, non-commercial use only, do the following:
            </li>
            <ol className="list-alpha list-inside ml-4">
              <li className="mt-4">
                retrieve, display, and view the Content on a computer screen
              </li>
            </ol>
            <li className="mt-4">
              You must not otherwise reproduce, modify, copy, distribute or use for commercial purposes any
              Content without the written permission of LawAdvisor. You also have no right to have access to
              the LawAdvisor Software in source code form or an unlocked coding or with comments and You
              have no right to and will not reverse engineer any of the LawAdvisor Software. In these Terms,&nbsp;
              <b>LawAdvisor Software</b> means the software underlying the LawAdvisor Product “LegalEye” that
              You have been given access to and all updates thereto from time to time.
            </li>
            <li className="mt-4">
              You acknowledge that you are responsible for any Content you may submit via the Website,
              including the legality, reliability, appropriateness, originality, and copyright of any such Content.
              You may not upload to, distribute or otherwise publish through the Website any Content that (i)
              is confidential, proprietary, false, fraudulent, libellous, defamatory, obscene, threatening,
              invasive of privacy or publicity rights, infringing on intellectual property rights, abusive, illegal or
              otherwise objectionable; (ii) may constitute or encourage a criminal offence, violate the rights of
              any party or otherwise give rise to liability or violate any law; or (iii) may contain software
              viruses, chain letters, mass mailings, or any form of &quot;spam.&quot; You may not use a false email
              address or other identifying information, impersonate any person or entity or otherwise mislead
              as to the origin of any content. You may not upload commercial content onto the Website.
            </li>
            <li className="mt-4">
              You represent and warrant that you own or otherwise control all the rights to the Content you
              post; that the Content is accurate; that use of the Content you supply does not violate any
              provision of these Terms and will not cause injury to any person; and that you will indemnify
              LawAdvisor for all claims resulting from Content you supply.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              User Generated Content
            </h3>
            <li className="mt-4">
              If it is the case that you supply/upload any content to the Website – whether it be pictures, text,
              sound recordings or whatever – the content you supply (&quot;<b>User Generated Content</b>&quot;) must
              comply with the following rules:
              <ol className="list-alpha list-inside ml-4">
                <li className="mt-4">
                  it must not be obscene, abusive, offensive or racist and it must not promote or propose
                  hatred or physical harm against anyone;
                </li>
                <li className="mt-4">
                it must not harass or bully another person;
                </li>
                <li className="mt-4">
                  it must be true and honest so far as you know;
                </li>
                <li className="mt-4">
                  it must not: (a) constitute pornography; or (b) be sexual or sexually suggestive involving
                  minors;
                </li>
                <li className="mt-4">
                  it must not be defamatory of anyone;
                </li>
                <li className="mt-4">
                  it must not use the material or content or infringe the rights or privacy of anyone else;
                  for example, you should not use images of well-known characters, footage or music
                  (unless it is your own or you have permission to use it);
                </li>
                <li className="mt-4">
                  it must not contain someone else’s personal details or confidential information relating
                  to other people;
                </li>
                <li className="mt-4">
                  it must not promote discrimination, whether based on race, sex, religion, nationality,
                  disability, sexual orientation or age;
                </li>
                <li className="mt-4">
                  it must not promote or condone terrorism, violence or illegal behaviour;
                </li>
                <li className="mt-4">
                  it must not be harmful to minors in any way;
                </li>
                <li className="mt-4">
                  it must not impersonate any person, or misrepresent your identity or affiliation with any
                  person;
                </li>
                <li className="mt-4">
                  it must not give the impression that it emanates from or is endorsed by us, if this is not
                  the case; and
                </li>
                <li className="mt-4">
                  it must not violate these Terms.
                </li>
              </ol>
            </li>
            <li className="mt-4">
              We take no responsibility for, and we do not expressly or implicitly endorse, any User Generated
              Content. By submitting your User Generated Content, you agree that you have all rights, power
              and authority necessary to grant the rights to such User Generated Content as set out in these
              Terms. As you alone are responsible for your User Generated Content, you may expose yourself
              to liability if you post or share User Generated Content without all necessary rights.
            </li>
            <li className="mt-4">
              We do not oversee, monitor or moderate any User Generated Content which is uploaded to the
              Website. If you become aware of any User Generated Content that breaches clause 3.2 above,
              please contact us at <a href="mailto:help@legaleye.com">help@legaleye.com</a>, providing details of:
              <ol className="list-alpha list-inside ml-4">
                <li className="mt-4">the date on which it was posted and where it can be found on the Website</li>
                <li className="mt-4">the username of the person who posted it;</li>
                <li className="mt-4">
                  reasons why the content should be deleted; and (d) copies of any communication with
                  the person who posted it (if any).
                </li>
              </ol>
            </li>
            <li className="mt-4">
              We reserve the right to refuse to accept or refuse or cease to use any User Generated Content
              supplied by any person that we think contravenes these rules.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Prohibited Use
            </h3>
            <li className="mt-4">
              You may not use the Website for any of the following purposes:
              <ol className="list-alpha list-inside ml-4">
                <li className="mt-4">
                  in any way which causes, or may cause, damage to the Website or interferes with any
                  other person&apos;s use or enjoyment of the Website;
                </li>
                <li className="mt-4">
                  in any way which is harmful, unlawful, illegal, abusive, harassing, threatening or
                  otherwise objectionable or in breach of any applicable law, regulation, governmental
                  order;
                </li>
                <li className="mt-4">
                  making, transmitting or storing electronic copies of Content protected by copyright
                  without the permission of the owner;
                </li>
                <li className="mt-4">
                  is in violation of these Terms.
                </li>
              </ol>
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Registration
            </h3>
            <li className="mt-4">
              When you register on this Website, you will be asked to create a password, which you should
              keep confidential and not disclose or share with anyone.
            </li>
            <li className="mt-4">
              You must ensure that the details provided by you on registration or at any time are correct and
              complete.
            </li>
            <li className="mt-4">
              You must inform us immediately of any changes to the information that you provide when
              registering by updating your personal details to ensure we can communicate with you effectively.
            </li>
            <li className="mt-4">
              We may suspend or cancel your registration with immediate effect for any reasonable purposes
              or if you breach these terms and conditions.
            </li>
            <li className="mt-4">
              You may cancel your registration at any time by informing us in writing to the address at the end
              of these terms and conditions. If you do so, you must immediately stop using the Website.
              Cancellation or suspension of your registration does not affect any statutory rights.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Confidentiality and Security
            </h3>
            <li className="mt-4">
              Each party shall keep confidential all, and shall not use for its own purposes (other than
              implementation of these Terms) nor without the prior written consent of the other party
              disclose to any third party (except its professional advisers or as required by law or a
              governmental or regulatory authority) any information of a confidential nature which may
              become known to such party from the other party and which relates to the other party or its
              Associated Companies, unless such knowledge is in the public domain or enters the public
              domain otherwise than as a breach of these Terms. Each party shall use at least the same degree
              of care to protect the other party’s confidential information as it does its own, but not less than
              a reasonable degree of care. A party shall promptly notify the other party if it becomes aware of
              an unauthorised disclosure of confidential information, and shall take reasonable steps to
              mitigate the negative impact of the unauthorised disclosure.
            </li>
            <li className="mt-4">
              Information put onto the LawAdvisor Platform by You shall be kept confidential by LawAdvisor
              save that LawAdvisor will permit access to those of its Users who have chosen to collaborate on
              a specific Matter and/or Project and save where that information is or comes into the public
              domain other than by the default of LawAdvisor. LawAdvisor shall have the right to carry out
              analytics and diagnostics on the LawAdvisor Platform and the information put onto it by You
              including in relation to all activity, use and information on the LawAdvisor Platform save that it
              shall only make available the results of such to others in an anonymised form and maintaining
              the confidentiality of the specific information put on by You. In these Terms, LawAdvisor
              Platform means the cloud platform on which the LawAdvisor Software is run together with the
              related storage for all files held and managed by way of the software and the LawAdvisor
              Software itself.
            </li>
            <li className="mt-4">
              If we have reason to believe that there is or is likely to be any misuse of the Website or breach of
              security, we may require you to change your password or suspend your account.
            </li>
            <li className="mt-4">
              LawAdvisor or its Associated Compnaies may appoint third parties (including LawAdvisor’s
              Associated Companies) as sub-processors under these Terms. A list of third parties (including
              LawAdvisor’s Associated Companies) approved by You as sub-processors under these Terms can
              be found on LawAdvisor’s website: <a href="/sub-processors">https://www.legaleye.com/sub-processors/</a>.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Links to Other Websites
            </h3>
            <li className="mt-4">
              This Website may contain links to other sites. Unless expressly stated, these sites are not under
              the control of LawAdvisor or that of our affiliates.
            </li>
            <li className="mt-4">
              We assume no responsibility for the content of such Websites and disclaim liability for any and
              all forms of loss or damage arising out of the use of them.
            </li>
            <li className="mt-4">
              The inclusion of a link to another site on this Website does not imply any endorsement of the
              sites themselves or of those in control of them.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Privacy Policy
            </h3>
            <li className="mt-4">
              Use of the Website is also governed by our Privacy Policy, which are incorporated into these
              terms and conditions as Schedule A below
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Availability of the Website and Disclaimers
            </h3>
            <li className="mt-4">
              Any online facilities, tools, services or information that LawAdvisor makes available through the
              Website (the “<b>Service</b>”) is provided &quot;as-is&quot; and on an &quot;as available&quot; basis. We give no warranty
              that the Service will be free of defects and/or faults. To the maximum extent permitted by the
              law, we provide no warranties (express or implied) of fitness for a particular purpose, accuracy of
              the information, compatibility, and satisfactory quality. LawAdvisor is under no obligation to
update information on the Website.
            </li>
            <li className="mt-4">
              Whilst LawAdvisor uses reasonable endeavours to ensure that the Website is secure and free of
              errors, viruses and other malware, we give no warranty or guarantee in that regard and all Users
              take responsibility for their own security, that of their personal details and their computers.
            </li>
            <li className="mt-4">
              LawAdvisor accepts no liability for any disruption or non-availability of the Website.
            </li>
            <li className="mt-4">
              LawAdvisor reserves the right to alter, suspend or discontinue any part (or the whole of) the
              Website including, but not limited to, any products and/or services available. These terms and
              conditions shall continue to apply to any modified version of the Website unless it is expressly
              stated otherwise.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Limitation of Liability
            </h3>
            <li className="mt-4">
              Nothing in these terms and conditions will: (a) limit or exclude our or your liability for death or
              personal injury resulting from our or your negligence, as applicable; (b) limit or exclude our or
              your liability for fraud or fraudulent misrepresentation; or (c) limit or exclude any of our or your
              liabilities in any way that is not permitted under applicable law
            </li>
            <li className="mt-4">
              We will not be liable to you in respect of any losses arising out of events beyond our reasonable
              control.
            </li>
            <li className="mt-4">
              To the maximum extent permitted by law, LawAdvisor accepts no liability for any of the
              following:
              <ol className="list-alpha list-inside ml-4">
                <li className="mt-4">
                  any business losses, such as loss of profits, income, revenue, anticipated savings,
                  business, contracts, goodwill or commercial opportunities;
                </li>
                <li className="mt-4">
                  loss or corruption of any data, database or software;
                </li>
                <li className="mt-4">
                  any special, indirect or consequential loss or damage.
                </li>
              </ol>
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              General
            </h3>
            <li className="mt-4">
              You may not transfer any of your rights under these Terms to any other person. We may transfer
              our rights under these Terms where we reasonably believe your rights will not be affected.
            </li>
            <li className="mt-4">
              These Terms may be varied by us from time to time. Such revised terms will apply to the Website
              from the date of publication by giving You 7 days notice. Users should check the Terms regularly
              to ensure familiarity with the then current version. In the event You do not agree to the revised
              Terms, you should stop using the Website immediately.
            </li>
            <li className="mt-4">
              These Terms together with the Privacy Policy contain the whole agreement between the parties
              relating to its subject matter and supersede all prior discussions, arrangements or agreements
              that might have taken place in relation to the Terms.
            </li>
            <li className="mt-4">
              If any court or competent authority finds that any provision of these Terms (or part of any
              provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent
              required, be deemed to be deleted, and the validity and enforceability of the other provisions of
              these Terms will not be affected.
            </li>
            <li className="mt-4">
              Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy
              will be deemed a waiver of that, or any other, right or remedy.
            </li>
            <li className="mt-4">
              This Agreement shall be governed by and interpreted according to the law of England and
              Wales, and all disputes arising under the Agreement (including non-contractual disputes or
              claims) shall be subject to the exclusive jurisdiction of the English and Welsh courts.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              LawAdvisor Details
            </h3>
            <li className="mt-4">
              LawAdvisor Ltd is a company incorporated in England and Wales with under the registeration
              number 11127712 whose registered address is Level 3, 115 Golden Ln, London EC1Y 0TJ and
              it operates the Website <a href="https://lawadvisor.com">www.lawadvisor.com</a> and all its
              sub-domains (&quot;<b>Website</b>&quot;).
            </li>
            <li className="mt-4">
              You can contact LawAdvisor by email on <a href="mailto:help@legaleye.com">help@legaleye.com</a>.
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  )
}

export default TermsOfService
